import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "./App.scss";

import Header from "./components/Header";
import SearchForm from "./components/SearchForm";
import OpenLibrarySearch from "./components/OpenLibrarySearch";
import Filter from "./components/Filter";
import ResultsContainer from "./components/ResultsContainer";
import FavoritesContainer from "./components/FavoritesContainer";
import LoadingOverlay from "./components/LoadingOverlay";
import ErrorMessage from "./components/ErrorMessage";
import NoResults from "./components/NoResults";
import EmbeddingVisualizer from "./components/EmbeddingVisualizer";
import BuyMeCoffeeButton from "./components/BuyMeCoffeeButton";
import CombinedAudioPlayer from "./components/CombinedAudioPlayer";

const MIN_QUERY_LENGTH = 3;
const YOUR_API_KEY = "b8ba5034-7930-481e-9b07-b138e5aab9fc";
const NUM_RESULTS = 6;

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const initialDarkMode = queryParams.get("darkMode") === "true";
  const initialQuery = queryParams.get("q") || "";

  const [searchTerm, setSearchTerm] = useState(initialQuery);
  const [query, setQuery] = useState(initialQuery);
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [error, setError] = useState("");
  const [humanOnly, setHumanOnly] = useState(false);
  const [bookDetails, setBookDetails] = useState({});
  const [noResults, setNoResults] = useState(false);
  const [darkMode, setDarkMode] = useState(initialDarkMode);
  const [favorites, setFavorites] = useState(
    JSON.parse(localStorage.getItem("favorites")) || []
  );
  const [showFavorites, setShowFavorites] = useState(false);
  const [favoriteDetails, setFavoriteDetails] = useState({});
  const [searchMode, setSearchMode] = useState("direct");
  const [highlightIds, setHighlightIds] = useState([]);
  const [currentAudioUrl, setCurrentAudioUrl] = useState(null);

  useEffect(() => {
    localStorage.setItem("favorites", JSON.stringify(favorites));
  }, [favorites]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("darkMode", darkMode);
    if (query) {
      queryParams.set("q", query);
    } else {
      queryParams.delete("q");
    }
    navigate({ search: queryParams.toString() }, { replace: true });
  }, [darkMode, query, navigate, location.search]);

  useEffect(() => {
    if (initialDarkMode === false) return;

    const currentHour = new Date().getHours();
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    if (prefersDarkMode || currentHour >= 18 || currentHour < 6) {
      setDarkMode(true);
    }
  }, [initialDarkMode]);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [darkMode]);

  const fetchResults = useCallback(
    async (searchQuery, isSimilarSearch = false, bookId = null) => {
      if (
        !isSimilarSearch &&
        searchMode === "direct" &&
        searchQuery.length < MIN_QUERY_LENGTH
      ) {
        setError(
          `Search query must be at least ${MIN_QUERY_LENGTH} characters long.`
        );
        return;
      }

      setIsLoading(true);
      setError("");
      setNoResults(false);
      try {
        let response;
        if (isSimilarSearch) {
          response = await axios.get(
            `https://gutenberg-api.patrickhoepner-cloudflare.workers.dev/similarById?id=${bookId}&topK=${NUM_RESULTS}`,
            { headers: { Authorization: `Bearer ${YOUR_API_KEY}` } }
          );

          if (response.data.matches.length === 0) {
            setNoResults(true);
            return;
          }

          // Modify results for similarity search: exclude last match and prepend search ID to both results and highlight IDs
          const matches = [
            { id: bookId },
            ...response.data.matches.slice(0, -1),
          ]; // Prepend search ID to matches
          const idsToHighlight = [bookId, ...matches.map((match) => match.id)]; // Prepend search ID to highlight IDs

          setHighlightIds(idsToHighlight);
          setResults(matches);
        } else {
          response = await axios.get(
            `https://gutenberg-api.patrickhoepner-cloudflare.workers.dev/query?text=${searchQuery}&topK=${NUM_RESULTS}`,
            { headers: { Authorization: `Bearer ${YOUR_API_KEY}` } }
          );

          if (response.data.matches.length === 0) {
            setNoResults(true);
            return;
          }

          // Standard behavior for non-similar search
          const idsToHighlight = response.data.matches.map((match) => match.id);
          setHighlightIds(idsToHighlight);
          setResults(response.data.matches);
        }
      } catch (error) {
        setError("Error fetching data. Please try again.");
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [searchMode]
  );

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();
      setQuery(searchTerm);
      fetchResults(searchTerm);
    },
    [fetchResults, searchTerm]
  );

  const handleSearchTermChange = useCallback((newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  }, []);

  const handleSimilarSearch = useCallback(
    (bookId) => {
      fetchResults("", true, bookId);
    },
    [fetchResults]
  );

  useEffect(() => {
    if (initialQuery && initialQuery.length >= MIN_QUERY_LENGTH) {
      setSearchTerm(initialQuery);
      setQuery(initialQuery);
      fetchResults(initialQuery);
    }
  }, [initialQuery, fetchResults]);

  useEffect(() => {
    const fetchBookDetails = async () => {
      setIsLoadingDetails(true);
      const newBookDetails = {};
      for (const result of results) {
        try {
          const response = await axios.get(
            `https://gutenberg-api.patrickhoepner-cloudflare.workers.dev/getById?id=${result.id}`,
            { headers: { Authorization: `Bearer ${YOUR_API_KEY}` } }
          );
          if (response.data && Object.keys(response.data).length > 0) {
            newBookDetails[response.data.Id] = response.data;
          }
        } catch (error) {
          console.error(`Error fetching details for book ${result.id}:`, error);
        }
      }
      setBookDetails(newBookDetails);
      setIsLoadingDetails(false);
    };

    if (results.length > 0) {
      fetchBookDetails();
    }
  }, [results]);

  useEffect(() => {
    const fetchFavoriteDetails = async () => {
      const newFavoriteDetails = {};
      for (const favoriteId of favorites) {
        if (!favoriteDetails[favoriteId]) {
          try {
            const response = await axios.get(
              `https://gutenberg-api.patrickhoepner-cloudflare.workers.dev/getById?id=${favoriteId}`,
              { headers: { Authorization: `Bearer ${YOUR_API_KEY}` } }
            );
            newFavoriteDetails[favoriteId] = response.data;
          } catch (error) {
            console.error(
              `Error fetching details for favorite book ${favoriteId}:`,
              error
            );
          }
        }
      }
      setFavoriteDetails((prevDetails) => ({
        ...prevDetails,
        ...newFavoriteDetails,
      }));
    };

    if (showFavorites && favorites.length > 0) {
      fetchFavoriteDetails();
    }
  }, [showFavorites, favorites, favoriteDetails]);

  const filteredResults = humanOnly
    ? results.filter((result) => bookDetails[result.id]?.Type === "LV")
    : results;

  const toggleFavorite = useCallback((book) => {
    setFavorites((prevFavorites) => {
      const isFav = prevFavorites.includes(book.id);
      if (isFav) {
        return prevFavorites.filter((favId) => favId !== book.id);
      } else {
        return [...prevFavorites, book.id];
      }
    });
  }, []);

  const isFavorite = useCallback(
    (bookId) => favorites.includes(bookId),
    [favorites]
  );

  const toggleSearchMode = useCallback(() => {
    setSearchMode((prevMode) =>
      prevMode === "direct" ? "openLibrary" : "direct"
    );
  }, []);

  const handlePlayAudio = useCallback((url) => {
    console.log("Playing audio:", url);
    setCurrentAudioUrl(url);
  }, []);

  return (
    <div className={`app-container`}>
      <Header
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        showFavorites={showFavorites}
        setShowFavorites={setShowFavorites}
      />

      <BuyMeCoffeeButton />
      {isLoading && <LoadingOverlay />}
      <main>
        {currentAudioUrl && (
          <div className="audio-container">
            <CombinedAudioPlayer url={currentAudioUrl} />
          </div>
        )}
        {showFavorites ? (
          <FavoritesContainer
            favorites={favorites}
            favoriteDetails={favoriteDetails}
            toggleFavorite={toggleFavorite}
            handleSimilarSearch={handleSimilarSearch}
            isFavorite={isFavorite}
            handlePlayAudio={handlePlayAudio}
          />
        ) : (
          <>
            <button onClick={toggleSearchMode} className="link-button hidden">
              Switch to {searchMode === "direct" ? "OpenLibrary" : "Direct"}{" "}
              Search
            </button>
            {searchMode === "direct" ? (
              <SearchForm
                searchTerm={searchTerm}
                setSearchTerm={handleSearchTermChange}
                handleSearch={handleSearch}
              />
            ) : (
              <OpenLibrarySearch
                onSearch={(query) => {
                  setSearchTerm(query);
                  setQuery(query);
                  fetchResults(query);
                }}
              />
            )}
            <Filter humanOnly={humanOnly} setHumanOnly={setHumanOnly} />
            <EmbeddingVisualizer
              fileUrl="/embeddings.ndjson"
              highlightIds={highlightIds}
              darkMode={darkMode}
            />

            <ErrorMessage error={error} />
            <NoResults noResults={noResults} />
            {filteredResults.length > 0 && (
              <ResultsContainer
                results={filteredResults}
                bookDetails={bookDetails}
                isLoadingDetails={isLoadingDetails}
                toggleFavorite={toggleFavorite}
                handleSimilarSearch={handleSimilarSearch}
                isFavorite={isFavorite}
                handlePlayAudio={handlePlayAudio}
              />
            )}
          </>
        )}
      </main>
    </div>
  );
};

export default App;
